import petShopBlueLogo from '../../../assets/petshopblue/petshopblue-logo.png';
import technologiesIcons from '../../../assets/petshopblue/technologies-icons.png';
import colorPaletteImg from '../../../assets/petshopblue/color-palette.jpg';
import typographyImg from '../../../assets/petshopblue/typography.jpg';
import styleTileImg from '../../../assets/petshopblue/style-tile.jpg';

export const hero = [
    {
        brandTitle: "Pet Shop Blue",
        fontFamily: "Rage",
        fontSizeSmall: "1.5rem",
        fontSize: "2.5rem",
        fontWeight: "100",
        heroTitle: "Building the Brand",
        colorTitle: "#164379",
        color: "#4995C4",
        title: "Overview",
        p1: "Pet Shop Blue is a pet shop located in Jundiaí, a mid-sized city near São Paulo, Brazil. The business is primarily focused on pet grooming, but is also a supplier of pet food and accessories.",
        p2: "My goal was to create a brand that reflects the company's values, such as the love for pets, dogs - specially Schauzers - pet care, protection, cleanliness and the Stakeholder's love for the color blue.",
        p3: "In the first iteration of this MVP, my role was to develop an application that enables users to learn about the details of the grooming services offered by the pet shop, as well as their opening hours, location and contact form.\nHere you can ",
        a1: "https://www.petshopblue.com.br/",
        a1Text: "visit the website.",
        img: petShopBlueLogo
    }
];

export const skills = [
    {
        hr: true,
        sectionTitle: "Skills",
        color: "#4995C4",
        title: "Technologies",
        p1: "The MVP has been developed with the full-stack MERN framework, consisting of MongoDB, ExpressJS, ReactJS and NodeJS. ",
        p2: "Extensive employment of reusable React functional components, React Hooks and modern JavaScript ES6 to build components such as cards, galleries and CTAs, applying the Atomic Design methodology to the design system.",
        p3: "Integration of Material UI library with production-ready components to speed up production and keep consistency across the user interface of the application. ",
        img: technologiesIcons,
        hover: false,
        modal: false,
        flexDirectionReverse: "column",
        orderReverse: -1,
    }
];

export const styleGuide = [
    {
        hr: true,
        sectionTitle: "Style Guide",
        color: "#4995C4",
        title: "Style Tile",
        p1: "Finding the balance between aesthetics and meaningful elements in the design is what I like to accomplish. Colors, font and every pixel play a very important role in building an emotional relationship with the user.",
        p2: "The Style Tile helps to form a common visual language between the designer and the stakeholders and provide a catalyst for discussions around the preferences and goals of the client.",
        p3: "",
        img: styleTileImg,
        hover: true,
        marginBottom: "3rem",
        modal: true,
        flexDirection: "column",
        order: 0
    }
];

export const typography = [
    {
        title: "Typography",
        p1: "Typography involves font style, appearance, and structure, which aims to elicit certain emotions and convey specific messages.",
        p2: "Heavy and playful typeface for the headlines and fluid rounded fonts for paragraph and text elements played a key role in conveying meaning to the layout in accordance with the design principles.",
        p3: "",
        img: typographyImg,
        hover: true,
        modal: true,
        marginBottom: "2rem",
        flexDirection: "column",
        order: 0
    }
];

export const colorPalette = [
    {
        title: "Color Palette",
        p1: "A color palette is a combination of colors used by UI designers when designing an interface.",
        p2: "The complementary color scheme chosen for the Pet Shop Blue had obviously to have shades of blue as primary color for backgrounds and visual elements and to bring warmth to the design - orange as accent color, so that these colors make each other appear brighter and create effective neutral hues and blend together for shadows.",
        p3: "",
        img: colorPaletteImg,
        hover: true,
        modal: true,
        marginBottom: "2rem",
        flexDirection: "column",
        order: 0
    }
];

export const designSystem = [
    {
        hr: true,
        sectionTitle: "Design System",
        color: "#4995C4",
        title: "Documentation",
        p1: "As part of the process of documentation of the Design System, all the elements are grouped in a design pattern, that is a reusable solution to a common design problem. The UI design pattern defines shape, color, and style of the elements. ",
        p2: "",
        p3: "",
        img: "",
        hover: true,
        modal: true,
        marginBottom: "2rem",
        gallery: true,
        flexDirection: "column",
        order: 0
    }
]