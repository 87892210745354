import React from "react";
import ReDoSliderLeft from "./ReDoSliderLeft";
import "./css/ReDoUserPersona.css";


function ReDoUserPersona(props) {
    const { sliderContent, sliderBGColor, isMobile, isTablet, isLaptop } = props;

    return (
        <section className="redo-user-persona-container">
            <div className="redo-user-persona-bg"></div>
            <div className="redo-user-persona-content">
                <article>
                    <h2>User Persona</h2>
                    <h3>Goals</h3>
                    <ul>
                        <li>Be on top of the latest trends</li>
                        <li>Dress and appear fashionable</li>
                        <li>Get more tech savvy</li>
                        <li>Want to wear the trends like clothes</li>
                    </ul>
                    <br />
                    <h3>Frustrations</h3>
                    <ul>
                        <li>Annoyed because magazines often offer excessive info</li>
                        <li>Bored because the magazines look similar</li>
                        <li>Confused because the online fashion magazines are <br />
                            sometimes disorganized
                        </li>
                    </ul>
                    <br />
                    {isLaptop ? (
                        null
                    ) : (
                        <>
                            <h3>Needs</h3>
                            <ul>
                                <li>Find fashion and trendy content</li>
                                <li>Wear the latest look</li>
                                <li>Read an online magazine with organized layout</li>
                                <li>Access to information without overload</li>
                            </ul>
                        </>
                    )}
                </article>
            </div>
            <ReDoSliderLeft sliderContent={sliderContent} sliderBGColor={sliderBGColor} isMobile={isMobile} isTablet={isTablet} />
        </section>
    );
}

export default ReDoUserPersona;