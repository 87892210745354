import React from "react";
import mockup01 from "../../assets/redo/redo-midfi-mockup01.png";
import mockup02 from "../../assets/redo/redo-midfi-mockup02.png";
import mockup03 from "../../assets/redo/redo-midfi-mockup03.png";
import mockup04 from "../../assets/redo/redo-midfi-mockup04.png";
import mockup05 from "../../assets/redo/redo-midfi-mockup05.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./css/ReDoMidFi.css";


function ReDoMidFi(props) {
    const {isMobile, isTablet} = props;

    const settingsTablet = {
        arrows: true,
        className: "center",
        centerMode: true,
        centerPadding: "0px",
        autoplay: false,
        autoplaySpeed: 500,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        pauseOnHover: false
    };

    const settingsDesktop = {
        arrows: true,
        className: "center",
        centerMode: true,
        centerPadding: "0px",
        autoplay: false,
        autoplaySpeed: 500,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        pauseOnHover: false
    };

    return (
        <section className="redo-midfi-container">
            <div className="redo-midfi-title-wrapper">
                <div className="redo-midfi-title">
                    <h3>Sketch & Wireframe Design</h3>
                    <hr/>
                </div>
            </div>
            {
                isTablet ? (
                    <Slider {...settingsTablet} className="redo-midfi-slider">
                <div className="redo-midfi-slider-item">
                    <div className="redo-midfi-slider-img">
                        <img src={mockup01} alt="Mockup"/>
                    </div>
                </div>
                <div className="redo-midfi-slider-item">
                    <div className="redo-midfi-slider-img">
                        <img src={mockup02} alt="Mockup"/>
                    </div>
                </div>
                <div className="redo-midfi-slider-item">
                    <div className="redo-midfi-slider-img">
                        <img src={mockup03} alt="Mockup"/>
                    </div>
                </div>
                <div className="redo-midfi-slider-item">
                    <div className="redo-midfi-slider-img">
                        <img src={mockup04} alt="Mockup"/>
                    </div>
                </div>
                <div className="redo-midfi-slider-item">
                    <div className="redo-midfi-slider-img">
                        <img src={mockup05} alt="Mockup"/>
                    </div>
                </div>
                <div className="redo-midfi-slider-item">
                    <div className="redo-midfi-slider-img">
                        <img src={mockup01} alt="Mockup"/>
                    </div>
                </div>
                <div className="redo-midfi-slider-item">
                    <div className="redo-midfi-slider-img">
                        <img src={mockup02} alt="Mockup"/>
                    </div>
                </div>
                <div className="redo-midfi-slider-item">
                    <div className="redo-midfi-slider-img">
                        <img src={mockup03} alt="Mockup"/>
                    </div>
                </div>
                <div className="redo-midfi-slider-item">
                    <div className="redo-midfi-slider-img">
                        <img src={mockup04} alt="Mockup"/>
                    </div>
                </div>
                <div className="redo-midfi-slider-item">
                    <div className="redo-midfi-slider-img">
                        <img src={mockup05} alt="Mockup"/>
                    </div>
                </div>
            </Slider>
                ) : (
                <Slider {...settingsDesktop} className="redo-midfi-slider">
                    <div className="redo-midfi-slider-item">
                        <div className="redo-midfi-slider-img">
                            <img src={mockup01} alt="Mockup"/>
                        </div>
                    </div>
                    <div className="redo-midfi-slider-item">
                        <div className="redo-midfi-slider-img">
                            <img src={mockup02} alt="Mockup"/>
                        </div>
                    </div>
                    <div className="redo-midfi-slider-item">
                        <div className="redo-midfi-slider-img">
                            <img src={mockup03} alt="Mockup"/>
                        </div>
                    </div>
                    <div className="redo-midfi-slider-item">
                        <div className="redo-midfi-slider-img">
                            <img src={mockup04} alt="Mockup"/>
                        </div>
                    </div>
                    <div className="redo-midfi-slider-item">
                        <div className="redo-midfi-slider-img">
                            <img src={mockup05} alt="Mockup"/>
                        </div>
                    </div>
                    <div className="redo-midfi-slider-item">
                        <div className="redo-midfi-slider-img">
                            <img src={mockup01} alt="Mockup"/>
                        </div>
                    </div>
                    <div className="redo-midfi-slider-item">
                        <div className="redo-midfi-slider-img">
                            <img src={mockup02} alt="Mockup"/>
                        </div>
                    </div>
                    <div className="redo-midfi-slider-item">
                        <div className="redo-midfi-slider-img">
                            <img src={mockup03} alt="Mockup"/>
                        </div>
                    </div>
                    <div className="redo-midfi-slider-item">
                        <div className="redo-midfi-slider-img">
                            <img src={mockup04} alt="Mockup"/>
                        </div>
                    </div>
                    <div className="redo-midfi-slider-item">
                        <div className="redo-midfi-slider-img">
                            <img src={mockup05} alt="Mockup"/>
                        </div>
                    </div>
                </Slider>
                )}
        </section>
    );
}

export default ReDoMidFi;