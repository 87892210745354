import React from "react";
import "./css/ReDoMoodboard.css";

function ReDoMoodboard() {

    return (
        <section className="redo-moodboard-container">
            <div className="redo-moodboard-bg-grey"></div>
            <div className="redo-moodboard-bg"></div>
        </section>
    );
}

export default ReDoMoodboard;