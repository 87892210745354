import ironhackLogo from "../../../assets/redo/ironhack-logo.png";
import iterationIcon from "../../../assets/redo/redo-iteration-icon.png";
import teamIcon from "../../../assets/redo/redo-team-icon.png";
import computerIcon from "../../../assets/redo/redo-computer-icon.png";
import mobileIcon from "../../../assets/redo/redo-mobile-icon.png";
import userIcon from "../../../assets/redo/redo-user-icon.png";
import noteIcon from "../../../assets/redo/redo-note-icon.png";
import dataIcon from "../../../assets/redo/redo-data-icon.png";
import behaviorIcon from "../../../assets/redo/redo-behavior-icon.png";
import socialMediaIcon from "../../../assets/redo/redo-social-media-icon.png";
import balloonIcon from "../../../assets/redo/redo-balloon-icon.png";
import interestIcon from "../../../assets/redo/redo-interest-icon.png";
import personalityIcon from "../../../assets/redo/redo-personality-icon.png";
import whiteMobileIcon from "../../../assets/redo/redo-white-mobile-icon.png";
import redoVogue from "../../../assets/redo/redo-vogue.jpg";
import redoDazed from "../../../assets/redo/redo-dazed.jpg";
import redoMktPositioning from "../../../assets/redo/redo-market-positioning.jpg";
import redoCompetitorsAnalysis from "../../../assets/redo/redo-competitors-analysis.jpg";
import avatar from "../../../assets/redo/redo-avatar-user-persona.png";
import redoJourneyMapImg from "../../../assets/redo/redo-journey-map.jpg";
import redoSitemap from "../../../assets/redo/redo-sitemap.jpg";
import redoUserflow from "../../../assets/redo/redo-userflow.jpg";
import redoStyleTile from "../../../assets/redo/redo-style-tile.jpg";
import redoStyleGuide01 from "../../../assets/redo/redo-style-guide01.jpg";
import redoStyleGuide02 from "../../../assets/redo/redo-style-guide02.jpg";
export const redoSliderBGColor = [
    "var(--redo-clr-pink)",
    "var(--redo-clr-grape)",
    "var(--redo-clr-blue"
]

export const redoSliderHero = [
    {
        id: "01",
        title: "UX | UI Design Bootcamp",
        p1: "Instructor: Juno Jo",
        p2: "Editorial Project for an",
        p3: "Online Magazine",
        img: ironhackLogo,
    },
    {
        id: "02",
        title: "Methodology",
        p1: "Design Thinking",
        p2: "Agile Method",
        p3: "1 Weekly Sprint",
        img: iterationIcon
    },
    {
        id: "03",
        title: "Team",
        p1: "Bruno Pulheze",
        p2: "Fanny Malongi",
        p3: "Nikolay Dimitrov",
        img: teamIcon
    },
    {
        id: "04",
        title: "Tools",
        p1: "Figma & Figjam",
        p2: "Useberry",
        p3: "Google Forms",
        img: computerIcon
    },
    {
        id: "05",
        title: "Deliverables",
        p1: "High-Fi Prototype",
        p2: "Responsive Design for",
        p3: "Browser",
        img: mobileIcon
    }
]

export const redoSliderUserResearch = [
    {
        id: "01",
        title: "Empathize",
        p1: "User Research",
        p2: "Survey",
        p3: "Interviews",
        img: userIcon
    },
    {
        id: "02",
        title: "Data Collected",
        p1: "User Insights",
        p2: "Pain Points",
        p3: "Gains",
        img: dataIcon
    },
    {
        id: "03",
        title: "UX Tools",
        p1: "Affinity Diagram",
        p2: "Empathy Map",
        p3: "HMW Statements",
        img: noteIcon
    },
    {
        id: "04",
        title: "UX Tools",
        p1: "UX Blueprint Diagram",
        p2: "Field Study",
        p3: "",
        img: noteIcon
    }
]

export const redoSliderUserPersona = [
    {
        id: "01",
        title: "Candice, 28",
        p1: "Single",
        p2: "Marketeer",
        p3: "Los Angeles, USA",
        img: avatar
    },
    {
        id: "02",
        title: "Behavior Traits",
        p1: "Trendy, Hardworking",
        p2: "Contemplative, Smart",
        p3: "Tech Savvy, Extrovert",
        img: behaviorIcon
    },
    {
        id: "03",
        title: "Influences",
        p1: "Tiktok, Instagram",
        p2: "Twitter, YouTube",
        p3: "Online Magazines",
        img: socialMediaIcon
    },
    {
        id: "04",
        title: "Quotation",
        p1: "“I think online",
        p2: "magazines could be",
        p3: "better organized”",
        img: balloonIcon
    },
    {
        id: "05",
        title: "Interests",
        p1: "Luxury Goods",
        p2: "Haute Couture",
        p3: "Tech Trends",
        img: interestIcon
    },
    {
        id: "06",
        title: "Personality",
        p1: "Extrovert, Creative",
        p2: "Busy, Organized",
        p3: "Independent",
        img: personalityIcon
    },
    {
        id: "07",
        title: "Devices",
        p1: "iPhone 12 Pro",
        p2: "iPad Air Pro",
        p3: "Macbook Pro 14”",
        img: whiteMobileIcon
    }
]

export const redoIntroArticle = [
    {
        id: "01",
        title: "Intro",
        p: "This project was developed during the UX UI Design Bootcamp at Ironhack. The task of creating a responsive online platform for a magazine, newspaper or blog directed to meet the needs and goals of one of the presented User Personas. Our User Persona is Candice, a trendy Marketeer, who loves fashion and to be on top of the latest trends, uses publications as a source of inspiration for work, and wants to get more tech savvy."
    },
    {
        id: "02",
        title: "Goals",
        p: "Our goal was to learn the pain points of online magazine readers and address them with a solution, building a new brand identity, creating prototypes for testing and iterate the process, in order to launch a trendy fashion online magazine."
    },
    {
        id: "03",
        title: "Constraints",
        p: "One of the main constraints during this project was the short timeframe of 5 days and many tasks we had. At this time we had to deliever responsive design prototypes for 3 devices and 2 breakpoints."
    },
    {
        id: "04",
        listTitle: "Timeline",
        list: [
            {
                listName: "User Research"
            },
            {
                listName: "Competitive Analysis"
            },
            {
                listName: "User Persona"
            },
            {
                listName: "Journey Map"
            },
            {
                listName: "Moodboard"
            },
            {
                listName: "Information Architecture"
            },
            {
                listName: "Wireframing"
            },
            {
                listName: "Style Guide"
            },
            {
                listName: "High-Fi"
            }
        ]
    }
]

export const redoCompetitiveAnalysisArticle = [
    {
        id: "01",
        title: "Competitive Analysis",
        p: "We started by comparing the layout and features the competitors have on their online presences. Our main competitors are Vogue, Dazed, Nylon, anOther, WWD, BOF, NYT Fashion, Wallpaper, Elle and Kinfolk. We used the Marketing Positioning Map to identify where our product currently lies in the market versus where we wanted it to be. Also, we wanted to identify the gaps in the market that we could take advantage of and turn it into an opportunity."
    },
    {
        id: "02",
        listTitle: "Competitors",
        list: [
            {
                listName: "Vogue"
            },
            {
                listName: "Dazed"
            },
            {
                listName: "Nylon"
            },
            {
                listName: "anOther"
            },
            {
                listName: "WWD"
            },
            {
                listName: "BoF"
            },
            {
                listName: "NYT Fashion"
            },
            {
                listName: "Wallpaper"
            }
        ]
    },
    {
        articleSlider: [
            {
                img: redoCompetitorsAnalysis
            },
            {
                img: redoMktPositioning
            },
            {
                img: redoVogue
            },
            {
                img: redoDazed
            }
        ]
    }
]

export const redoJourneyMap = [
    {
        id: "01",
        title: "Journey Map",
        p: "This is a journey map based on the User Persona, which allows us to visualize the user journey and to accurately show what the actual user experience is."
    },
    {
        id: "02",
        listTitle: "Scenario",
        list: [
            {
                listName: `– Candice is a trendy marketeer. She wants to learn more about technology and become more tech savvy.`
            },
            {
                listName: `– Her goal is to find all the relative information trendwise about the topics she is interested in Fashion and Technology`
            }
        ]
    },
    {
        id: "03",
        listTitle: "Expectations",
        list: [
            {
                listName: "– Be on top of the latest trends"
            },
            {
                listName: "– Dress and appear fashionable"
            },
            {
                listName: "– Get more tech savvy"
            },
            {
                listName: "– Find a magazine that is of her interest"
            }
        ]
    },
    {
        id: "04",
        articleImage: redoJourneyMapImg
    }
]

export const redoIAArticle = [
    {
        id: "01",
        title: "Information Architecture",
        p: "Once the problem was defined, we created a Sitemap with the minimal features that our online magazine could operate and also organized the User Flow focused on the task of visiting our online magazine coming from Instagram, Candice will land on a category page, she will then navigate to an article and then go to the homepage, where she can decide to apply the semantic filter or not."
    },
    {
        id: "02",
        articleSlider: [
            {
                img: redoSitemap
            },
            {
                img: redoUserflow
            }
        ]
    },
    {
        id: "03",
        listTitle: "IA Tools",
        list: [
            {
                listName: "User Flow",
                listText: "User flow reflects the user's progress on the focused task of reading an article and filtering the content on the homepage"
            },
            {
                listName: "Site Map",
                listText: "A sitemap can help clarify the site’s goals before you start designing or creating content."
            }
        ]
    }
]

export const redoStyleGuide = [
    {
        id: "01",
        title: "Style Guide",
        p: "Finding the balance between aesthetics and meaningful elements in the design is what we like to accomplish. Colors, font and every pixel play a very important role in building an emotional relationship with the user."
    },
    {
        id: "02",
        articleSlider: [
            {
                img: redoStyleTile
            },
            {
                img: redoStyleGuide01
            },
            {
                img: redoStyleGuide02
            }
        ]
    },
    {
        id: "04",
        listTitle: "Design Principles",
        list: [
            {
                listName: "Simplicity"
            },
            {
                listName: "Moodish"
            },
            {
                listName: "Trendy"
            },
            {
                listName: "Tech Savvy"
            }
        ]
    }
]