import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import GithubIcon from '../svg-icons/GithubIcon';
import MediumIcon from '../svg-icons/MediumIcon';
import LinkedIn from "../svg-icons/LinkedInIcon";
import { Link } from "react-router-dom";
import './css/Sidebar.css';

function Sidebar(props) {
    const { sidebar, showSidebar } = props;

    return (
        <div className={sidebar ? 'sidebar-menu active' : 'sidebar-menu'}>
            <ul className='sidebar-menu-items' onClick={showSidebar}>
                <li className='sidebar-toggle'>
                    <Link to='#' className='sidebar-toggle-header'>
                        <CloseIcon />
                    </Link>
                </li>
                <li className="sidebar-text">
                    <Link to="/">
                        <div className='home-icon'></div>
                        {/* <NewspaperIcon /> */}
                        <span>Home</span>
                    </Link>
                </li>
                <li className="sidebar-text">
                    <Link to="/portfolio">
                        <div className='portfolio-icon'></div>
                        {/* <NewspaperIcon /> */}
                        <span>Portfolio</span>
                    </Link>
                </li>
                <li className="sidebar-text">
                    <Link to="/about">
                        <div className='about-icon'></div>
                        {/* <PersonPinIcon /> */}
                        <span>About Me</span>
                    </Link>
                </li>
                <ul className="sidebar-icons">
                    <li>
                        <a href="https://www.linkedin.com/in/bruno-pulheze/" target="_blank">
                            <LinkedIn />
                        </a>
                    </li>
                    <li>
                        <a href='https://medium.com/@brunopulheze' target='_blank'>
                            <MediumIcon />
                        </a>
                    </li>
                    <li >
                        <a href='https://github.com/brunopulheze' target='_blank'>
                            <GithubIcon />
                        </a>
                    </li>
                </ul>
            </ul>
        </div>
    );
}

export default Sidebar;