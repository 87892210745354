import React, { useState, useEffect, useCallback } from "react";
import Medium from "../svg-icons/MediumIcon";
import Github from "../svg-icons/GithubIcon";
import LinkedIn from "../svg-icons/LinkedInIcon";
import { NavLink } from "react-router-dom";
import "./css/Navbar.css";

export default function () {
    const [stickyClass, setStickyClass] = useState('');
    const [y, setY] = useState(window.scrollY);

    const handleNavigation = useCallback(
        e => {
            const window = e.currentTarget;
            if (y > window.scrollY) {
                setStickyClass('');
            } else if (y < window.scrollY) {
                setStickyClass('sticky-nav');
            }
            setY(window.scrollY);
        }, [y]
    );

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    return (
        <nav className={stickyClass}>
            <div className="navbar-container">
                <ul>
                    <li>
                        <NavLink
                            to="/"
                        >
                            <div className="navbar-logo" ></div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/"
                        >
                            Home
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/portfolio"
                        >
                            Portfolio
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/about"
                        >
                            About Me
                        </NavLink>
                    </li>
                </ul>
                <div className="nav-icons">
                    <ul>
                        <li>
                            <a href="https://www.linkedin.com/in/bruno-pulheze/" target="_blank">
                                <LinkedIn />
                            </a>
                        </li>
                        <li>
                            <a href="https://medium.com/@brunopulheze/" target="_blank">
                                <Medium />
                            </a>
                        </li>
                        <li>
                            <a href="https://github.com/brunopulheze" target="_blank">
                                <Github />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}