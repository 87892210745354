import React from "react";
import "./css/StarbucksLogo.css";

const StarbucksLogo = () => {
    return (
        <svg
            className="starbucks-logo"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 211.83 21.28"
        >
            <defs>
            </defs>
            <g id="Layer_x0020_1">
                <g id="_2341530433024">
                    <path className="fil0" d="M102.67 16.17l-2.66 0 0 -3.54 2.66 0c1.19,0 2.06,0.64 2.06,1.77 0,1.13 -0.87,1.77 -2.06,1.77zm-0.89 -7.67l-1.77 0 0 -3.54c2.67,0 4.43,-0.38 4.43,2.07 0,1.15 -1.46,1.47 -2.66,1.47zm-8.85 12.39c4.35,0 13.04,0.54 16.34,-1.07 3.78,-1.84 3.27,-6.43 1.69,-8.11 -0.61,-0.64 -1.94,-1.13 -2.39,-1.44 5.04,-1.17 3.98,-9.74 -2.66,-9.74l-12.98 0 0 20.36z" />
                    <path className="fil0" d="M77.89 9.39l-2.36 0 0 -3.84 2.36 0c1.19,0 2.06,0.64 2.06,1.77 0,1.38 -0.69,2.07 -2.06,2.07zm-9.74 10.62c0,1.47 2.51,0.88 6.49,0.88 1.46,0 0.89,-2.32 0.89,-6.19 2.09,0 1.22,-0.19 3.07,2.82 0.6,0.98 1.66,2.32 1.94,3.37l7.67 0c-0.37,-1.59 -3.73,-5.8 -4.72,-7.67 5.99,-3.17 4.42,-12.69 -3.54,-12.69l-11.8 0 0 19.48z" />
                    <path className="fil0" d="M164.63 20.01c0,1.54 3.12,0.88 7.37,0.88l0 -6.78c0.61,0.44 1.77,2.48 2.27,3.33 0.76,1.29 1.39,2.3 2.16,3.45l8.26 0c-0.11,-1.31 -0.06,-0.69 -1.52,-2.61l-5.56 -7.71c0.28,-1.06 2.45,-3.98 3.19,-5.08 1.14,-1.71 2.19,-3.3 3.3,-4.96l-8.26 0c-0.21,0.89 -1.32,2.66 -1.83,3.49l-2.01 3.3 0 -6.79 -7.37 0 0 19.48z" />
                    <path className="fil0" d="M52.22 5.84c0.47,0.75 0.69,2.17 0.88,2.96 0.28,1.14 0.64,2.17 0.89,3.24l-3.25 0c0.04,-1.86 1.08,-4.5 1.48,-6.2zm-11.8 15.05l7.37 0c0.13,-1.53 0.83,-2.34 1.18,-3.83l6.79 0c0.09,1.09 0.69,3.83 1.77,3.83 8.53,0 7.35,1.69 4.24,-7.19l-4.83 -13.17 -9.44 0c-0.14,1.71 -6.89,18.09 -7.08,20.36z" />
                    <path className="fil0" d="M116.83 13.52c0.88,1.84 1.15,7.63 9.73,7.68 4.11,0.03 7.25,-1.04 9.15,-4.15 0.73,-1.19 0.74,-2.11 1.18,-3.5 -0.59,-0.49 -0.34,-11.02 -0.34,-12.65l-6.86 -0.13 0.02 9.5c0.03,0.97 0.29,1.98 -0.19,2.77 -0.8,1.3 -0.35,2.28 -2.66,2.28 -2.37,0 -1.82,-0.99 -2.65,-2.26l-0.21 -12.2 -6.85 -0.01c0.01,2.38 0.41,11.25 -0.32,12.67z" />
                    <path className="fil0" d="M0.59 6.73c0,7.82 7.94,4.88 9.73,8.26 -1.22,1.83 -3.54,1.25 -5.49,0.48 -1.31,-0.52 -1.46,-1.06 -2.77,-1.36l-2.06 4.42c6.47,4.34 17.99,3.77 17.99,-4.42 0,-5.49 -6.05,-6.02 -9.05,-6.89 -3.53,-1.03 -0.58,-4.34 6.69,-0.49 0.4,-1.71 1.35,-2.9 1.77,-4.72 -3.71,-1.96 -10.19,-3.15 -14.17,-0.31 -1.33,0.95 -2.64,2.8 -2.64,5.03z" />
                    <path className="fil0" d="M187.64 5.84c0,4.74 1.27,6.2 6.67,7.5 5.57,1.34 2.17,3.94 -2.42,2.13 -1.1,-0.43 -1.85,-1.12 -2.78,-1.36 -0.56,1.06 -1.95,3.44 -2.06,4.72 1.14,0.3 8.62,4.69 14.82,0.95 3.25,-1.96 5.19,-8.4 -1.25,-11.28 -1.11,-0.5 -2.03,-0.67 -3.47,-0.95 -1.43,-0.29 -2.58,-0.29 -2.72,-2 2.81,-1.88 6.22,0.71 8.26,1.18 0.08,-0.98 1.35,-3.93 1.77,-4.72 -2.96,-1.56 -7.44,-2.51 -11.39,-1.65 -2.57,0.56 -5.43,3.17 -5.43,5.48z" />
                    <path className="fil0" d="M151.35 21.19c2.6,0 3.04,0.07 5.62,-0.58 1.4,-0.36 2.05,-0.92 3.23,-1.19 -0.48,-2.06 -1.57,-3.48 -2.06,-5.61 -1.98,1.05 -2.26,1.77 -5.02,1.77 -6.15,0 -5.73,-10.33 0,-10.33 1.92,0 3.71,1.38 5.02,2.07l2.06 -5.31c-4.73,-2.5 -11.65,-3.12 -15.72,0.8 -4.42,4.28 -4.32,10.84 -0.72,15.05 1.38,1.63 4.67,3.33 7.59,3.33z" />
                    <path className="fil0" d="M26.55 6.02c0,1.22 -0.17,13.05 0.12,14.16 0.02,0.06 0.07,0.16 0.08,0.21 0.12,0.36 0.64,0.44 0.99,0.49 0.35,0.04 1.08,0.03 1.45,0.02 6.11,-0.06 5.06,0.54 5.03,-3.55 -0.02,-3.86 0,-7.74 0,-11.61l5.57 -0.06 -0.01 -4.79 -18.81 0.01 -0.08 4.77 5.66 -0.06c0.04,0.05 0.15,0.11 0.16,0.12l-0.16 0.29z" />
                    <path className="fil0" d="M26.55 6.02l0.16 -0.29c-0.01,-0.01 -0.12,-0.07 -0.16,-0.12l-5.66 0.06 0.08 -4.77 18.81 -0.01 0.01 4.79 -5.57 0.06 5.9 0.11 0 -5.32 -19.47 0 0 5.31 5.9 0.18z" />
                    <path className="fil0" d="M129.52 13.04c0.48,-0.79 0.22,-1.8 0.19,-2.77l-0.02 -9.5 6.86 0.13c0,1.63 -0.25,12.16 0.34,12.65l0 -13.02 -7.37 0 0 12.51z" />
                    <path className="fil0" d="M116.83 13.52c0.73,-1.42 0.33,-10.29 0.32,-12.67l6.85 0.01 0.21 12.2 0 -12.53 -7.38 0 0 12.99z" />
                    <path className="fil0" d="M209.77 4.37c-0.72,-0.17 -1.19,-0.31 -1.77,-0.59 -0.85,-3.53 1.88,-4.61 3.54,-1.48 -0.8,1.67 -0.02,1.14 -1.77,2.07zm-2.66 -2.07c0,1.35 0.63,1.54 1.18,2.36 2.11,0 3.54,0.27 3.54,-2.36 0,-3.14 -4.72,-2.77 -4.72,0z" />
                    <path className="fil0" d="M209.18 1.71l0.88 0 0 0.59 -0.88 0 0 -0.59zm-0.59 1.77c0.07,-0.02 0.18,-0.17 0.21,-0.1 0.09,0.19 0.06,-0.31 0.69,-0.34 0.48,-0.03 0.17,0.18 1.16,0.44 -0.85,-1.78 1.5,-2.36 -2.06,-2.36l0 2.36z" />
                </g>
            </g>
        </svg>
    );
}

export default StarbucksLogo;