import React from 'react';
import SectionHero from '../../components/section-hero/SectionHero';
import Section from '../../components/section/Section';
import { hero, skills } from './helpers/starbucks-data';
import './css/Starbucks.css';

const Starbucks = (props) => {
    const { isMobile, isTablet, isDesktop } = props;

    return (
        <main className='starbucks-container'>
            <SectionHero data={hero} isMobile={isMobile} isTablet={isTablet} />
            <Section data={skills} isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop} />
        </main>
    );
}

export default Starbucks;