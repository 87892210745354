import React from "react";
import "./css/LinkedInIcon.css";

const LinkedInIcon = () => {
    return (
        <svg className="linkedin-icon" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.16699 3.31108C1.16699 2.74237 1.39291 2.19695 1.79505 1.79481C2.19719 1.39267 2.74261 1.16675 3.31133 1.16675H24.687C24.9688 1.16629 25.248 1.22142 25.5085 1.329C25.769 1.43657 26.0057 1.59446 26.2051 1.79364C26.4045 1.99282 26.5627 2.22938 26.6705 2.48976C26.7784 2.75015 26.8338 3.02925 26.8337 3.31108V24.6867C26.834 24.9686 26.7787 25.2478 26.671 25.5083C26.5633 25.7688 26.4053 26.0056 26.206 26.2049C26.0067 26.4043 25.7701 26.5625 25.5097 26.6703C25.2492 26.7781 24.97 26.8336 24.6882 26.8334H3.31133C3.02963 26.8334 2.75069 26.7779 2.49046 26.6701C2.23022 26.5622 1.99378 26.4042 1.79464 26.2049C1.59551 26.0057 1.43758 25.7692 1.32989 25.5089C1.22219 25.2486 1.16684 24.9696 1.16699 24.6879V3.31108ZM11.3263 10.9528H14.8018V12.6981C15.3035 11.6948 16.5868 10.7918 18.5153 10.7918C22.2125 10.7918 23.0887 12.7903 23.0887 16.4571V23.2494H19.3472V17.2924C19.3472 15.2041 18.8455 14.0258 17.5715 14.0258C15.804 14.0258 15.069 15.2963 15.069 17.2924V23.2494H11.3263V10.9528ZM4.90966 23.0896H8.65233V10.7918H4.90966V23.0884V23.0896ZM9.18783 6.78075C9.19488 7.1012 9.13786 7.41984 9.02011 7.71795C8.90235 8.01607 8.72624 8.28766 8.5021 8.51679C8.27796 8.74592 8.01031 8.92797 7.71486 9.05226C7.41941 9.17655 7.10211 9.24057 6.78158 9.24057C6.46105 9.24057 6.14375 9.17655 5.8483 9.05226C5.55284 8.92797 5.2852 8.74592 5.06106 8.51679C4.83692 8.28766 4.6608 8.01607 4.54305 7.71795C4.42529 7.41984 4.36827 7.1012 4.37533 6.78075C4.38918 6.15175 4.64877 5.55317 5.09853 5.11322C5.54828 4.67326 6.15242 4.4269 6.78158 4.4269C7.41073 4.4269 8.01488 4.67326 8.46463 5.11322C8.91438 5.55317 9.17397 6.15175 9.18783 6.78075V6.78075Z" />
        </svg>

    );
}

export default LinkedInIcon;