import starbucksHero from '../../../assets/starbucks/starbucks-logo.png';
import technologiesIcons from '../../../assets/starbucks/technologies-icons.png';


export const hero = [
    {
        brandTitle: "Starbucks",
        fontFamily: "Readex Pro",
        fontSizeSmall: "1.5rem",
        fontSize: "2.5rem",
        fontWeight: "100",
        heroTitle: "Homepage Clone",
        colorTitle: "#006341",
        color: "#4995C4",
        title: "Overview",
        p1: "Starbucks is the world's largest coffeehouse chain. Their goal is to create a culture of warmth and belonging, where everyone is welcome.",
        p2: "In order to have a most helpful homepage, the company decided to change their approach and started quieting down the functional elements on the homepage layout - so customers can find exactly what they seek. This turned out to be a perfect opportunity for me to clone Starbuck's functional homepage by building a responsive layout.",
        p3: "You can visit the website",
        a1: "https://brunopulheze.github.io/starbucks-clone-react-tailwind/",
        a1Text: "here ",
        p4: "or view the repository on",
        a2: "https://github.com/brunopulheze/starbucks-clone-react-tailwind",
        a2Text: "Github.",
        img: starbucksHero
    }
];

export const skills = [
    {
        hr: true,
        sectionTitle: "Skills",
        color: "#4995C4",
        title: "Technologies",
        p1: "The main goal of this project was to clone the homepage of Starbucks by creating functional components with React JS while learning and implementing utility classes with Tailwind CSS. ",
        p2: "The objective is to speed up production while taking advantage of Tailwind CSS dynamic breakpoints, enabling a mobile first approach and reducing the file size on the client side.",
        p3: "Noteworthy are the use of React Hooks, CSS Animations and reusable components from UI libraries.",
        img: technologiesIcons,
        hover: false,
        modal: false,
        flexDirectionReverse: "column",
        orderReverse: -1,
        marginBottom: "10rem"
    }
];
