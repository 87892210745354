import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Dog from "../../assets/petshopblue/gallery-dog.png";
import Cat from "../../assets/petshopblue/gallery-cat.png";
import Bird from "../../assets/petshopblue/gallery-bird.png";
import Fish from "../../assets/petshopblue/gallery-fish.png";
import Hamster from "../../assets/petshopblue/gallery-hamster.png";
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import './css/SectionGallery.css';

const SectionGallery = (props) => {
    const { isMobile } = props;
    const settings = {
        autoplay: true,
        autoplaySpeed: 4000,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
        nextArrow: <ArrowForwardIosOutlinedIcon />,
        prevArrow: <ArrowBackIosOutlinedIcon />
    };

    return (
        <div className='section-gallery-container'>
            <div className='section-gallery-content'>
                <Slider {...settings}>
                    <div className='section-gallery-item'>
                        <img src={Dog} alt="dog" />
                        <div className='section-gallery-bg'></div>
                        <h3>Dogs</h3>
                    </div>
                    <div className='section-gallery-item'>
                        <img src={Cat} alt="dog" />
                        <div className='section-gallery-bg'></div>
                        <h3>Cats</h3>
                    </div>
                    <div className='section-gallery-item'>
                        <img src={Bird} alt="dog" />
                        <div className='section-gallery-bg'></div>
                        <h3>Birds</h3>
                    </div>
                    <div className='section-gallery-item'>
                        <img src={Fish} alt="dog" />
                        <div className='section-gallery-bg'></div>
                        <h3>Fish</h3>
                    </div>
                    <div className='section-gallery-item'>
                        <img src={Hamster} alt="dog" />
                        <div className='section-gallery-bg'></div>
                        <h3>Hamsters</h3>
                    </div>
                </Slider>
            </div>
        </div>
    );
}

export default SectionGallery;