import React, { useState, useEffect } from 'react';
import PetShopBlueProjectCard from './PetShopBlueProjectCard';
import StarbucksProjectCard from './StarbucksProjectCard';
import GreetNowProjectCard from './GreetNowProjectCard';
import MystikaProjectCard from './MystikaProjectCard';
import ECommerceProjectCard from './ECommerceProjectCard';
import { NavLink as Link } from "react-router-dom";
import './css/Projects.css';

const Projects = () => {

    return (
        <div className='projects-container'>
            <Link to="/petshopblue" className="project-card">
                <PetShopBlueProjectCard />
                <div className='project-card-text'>
                    <h3>Branding & Full Stack Development</h3>
                    <h2>Pet Shop Blue</h2>
                    <p>Creating the brand identity and developing the website for a small business with React JS</p>
                </div>
            </Link>
            <Link to="/starbucks" className="project-card">
                <StarbucksProjectCard />
                <div className='project-card-text'>
                    <h3>Frontend Development</h3>
                    <h2>Starbucks Clone</h2>
                    <p>Cloning the Starbucks' Homepage with React JS and utility classes Tailwind CSS</p>
                </div>
            </Link>
            <Link to="/e-commerce" className="project-card">
                <ECommerceProjectCard />
                <div className='project-card-text'>
                    <h3>Branding & Frontend Development</h3>
                    <h2>E-Commerce</h2>
                    <p>Developing the UI and Frontend with React TypeScript of a shopping cart</p>
                </div>
            </Link>
            <Link to="#" className="project-card">
                <GreetNowProjectCard />
                <div className='project-card-text'>
                    <h3>Branding & Frontend Development</h3>
                    <h2>Greet Now</h2>
                    <p>Branding and Developing of the UI and Frontend for the Web Dev Final Project at DCI Berlin</p>
                </div>
            </Link>
            <Link to="#" className="project-card">
                <MystikaProjectCard />
                <div className='project-card-text'>
                    <h3>Branding & Frontend Development</h3>
                    <h2>Mystika</h2>
                    <p>Designing UX UI and Developing the Web App for tarot readings with React JS </p>
                </div>
            </Link>

        </div>
    );
};

export default Projects;