import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import './css/Modal.css';

const Modal = ({
    clickedImg,
    setClickedImg,
}) => {
    const handleClick = (e) => {
        if (e.target.classList.contains("modal-dismiss")) {
        setClickedImg(null);
        }
    };

    return (
        <>
            <div className="modal-overlay modal-dismiss" onClick={handleClick}>
                <img src={clickedImg} alt="" />
                <span>
                    <CloseIcon className="modal-dismiss" onClick={handleClick}/>
                </span>
            </div>
        </>
    );
};

export default Modal;