import React from "react";
import './css/SectionHero.css';

const SectionHero = (props) => {
    const { isMobile, isTablet, data } = props;

    return (
        <div className='section-hero-container'>
            {isTablet ?
                data?.map((item, i) => (
                    <div key={i} className='section-hero-content'>
                        <div className="section-hero-header">
                            <h1>Bruno's Portfolio</h1>
                            <p>Frontend Developer | Webdesigner</p>
                        </div>
                        <div className='section-hero-box'>
                            <div className='section-hero-item'>
                                <div className='section-hero-item-img'>
                                    <img src={item.img} alt="" />
                                </div>
                            </div>
                            <div className='section-hero-item'>
                                <h3 style={{ color: item.color }}>{item.heroTitle}</h3>
                            </div>
                            <div className='section-hero-item'>
                                <h3>{item.title}</h3>
                                <p>{item.p1}</p>
                                <p>{item.p2}</p>
                                <p>{item.p3} <a href={item.a1} target="/blank" style={{ color: item.color }}>{item.a1Text}</a> {item.p4} <a href={item.a2} target="/blank" style={{ color: item.color }}>{item.a2Text}</a></p>

                            </div>

                        </div>
                    </div>
                )) :
                data?.map((item, i) => (
                    <div key={i} className='section-hero-content'>
                        <div className="section-hero-header">
                            <div>
                                <h2 style={{
                                    fontFamily: item.fontFamily,
                                    fontSize: isMobile ? item.fontSizeSmall : item.fontSize,
                                    fontWeight: item.fontWeight,
                                    color: item.colorTitle
                                }}>
                                    {item.brandTitle}
                                </h2>
                            </div>
                            <div>
                                <h1>Bruno's Portfolio</h1>
                                <p>Frontend Developer | Webdesigner</p>
                            </div>
                        </div>
                        <div className='section-hero-box'>
                            <div className='section-hero-item'>
                                <h3 style={{ color: item.color }}>{item.heroTitle}</h3>
                            </div>
                            <div className='section-hero-item'>
                                <h3>{item.title}</h3>
                                <p>{item.p1}</p>
                                <p>{item.p2}</p>
                                <p>{item.p3} <a href={item.a1} target="/blank">{item.a1Text}</a> {item.p4} <a href={item.a2} target="/blank">{item.a2Text}</a></p>
                            </div>
                            <div className='section-hero-item'>
                                <div className="section-hero-item-img">
                                    <img src={item.img} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
}

export default SectionHero;