import React, { useState, useEffect, useRef } from "react";
import rectangle from "../../assets/home/rectangle.png";
import "./css/GreetNowProjectCard.css";

const GreetNowProjectCard = (props) => {
    const { isMobile } = props;
    const [height, setHeight] = useState();
    const targetRef = useRef();

    const changeHeight = () => {
        const newHeight = targetRef.current.clientHeight;
        setHeight(newHeight);
    };

    useEffect(() => {
        setHeight(targetRef.current.clientHeight);
    });

    useEffect(() => {
        changeHeight();
    }, [targetRef]);

    useEffect(() => {
        window.addEventListener("resize", changeHeight);
    }, []);

    return (
        <div className="greetnow-project-card-container" data-ribbon="Coming soon" style={{ height: height }}>
            <div className="rectangle">
                <img ref={targetRef} src={rectangle} alt="" />
            </div>
            <div className="greetnow-project-card-content">
                <div className="greetnow-project-card-bg"></div>
            </div>
        </div>
    );
}

export default GreetNowProjectCard;