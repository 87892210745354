import React from "react";
import ReDoSliderRight from "./ReDoSliderRight";
import "./css/ReDoUserResearch.css";


function ReDoUserResearch(props) {
    const {sliderContent, sliderBGColor, isMobile, isTablet} = props;
    
    return (
        <section className="redo-user-research-container">
            <div className="redo-user-research-bg"></div>
            <div className="redo-user-research-content">
                <div className="redo-user-research-text">
                    <h2>User Research</h2>
                    <p>Through an online Survey and personal Interviews with readers of online magazines, we found out that: <br/> <br/>
                    <ul>
                        <li>
                            <strong>95%</strong> of people read online magazines on mobile devices
                        </li>
                        <li>
                            <strong>50%</strong> reads online magazines on public transport
                        </li>
                        <li>
                            <strong>55%</strong> of users are most interested about fashion
                        </li>
                        <li>
                            <strong>33%</strong> are looking for trends
                        </li>
                        <li>
                            <strong>80%</strong> uses magazines as source of inspiration
                        </li>
                        <li>
                            – Users also complain about excessive information on online magazines
                        </li>
                    </ul>
                    <br/>
                    <strong>Problem Statement</strong>
                    <br/>
                    <br/>
                    “Users cannot find the content they are interested about because they are overwhelmed with the amount of articles and the distracting subject matter online magazines offer”</p>
                </div>
            </div>
            <ReDoSliderRight sliderContent={sliderContent} sliderBGColor={sliderBGColor} isMobile={isMobile} isTablet={isTablet} />
        </section>
    );
}

export default ReDoUserResearch;