import React, { useState } from "react";
import Modal from "../../components/modal/Modal";
import "./css/ReDoArticleLeft.css";

function ReDoArticleLeft(props) {
    const { articleContent, isMobile, isTablet } = props;
    const [clickedImg, setClickedImg] = useState(null);

    const handleClick = (item) => {
        setClickedImg(item.articleImage);
    };

    const [isOpen, setIsOpen] = useState(false);
    const handleSlide = () => setIsOpen(!isOpen);
    const closeSlide = () => setIsOpen(false);

    return (
        <section className="redo-article-container-left">
            <div className={isOpen ? "redo-article-aside-bg-left active" : "redo-article-aside-bg-left"} onClick={handleSlide}></div>
            <div className="redo-article-content-wrapper-left" onClick={closeSlide}>
                <div className={isOpen ? "redo-article-content-left active" : "redo-article-content-left"}>
                    {articleContent.map((item, i) =>
                        <article key={item.id}>
                            <h2>{item.title}</h2>
                            <p>
                                {item.p}
                            </p>
                            {item.articleImage ? (

                                <div className="redo-article-item-img-left">
                                    <img src={item.articleImage} alt="" onClick={() => handleClick(item, i)} />
                                </div>
                            ) : (null)}
                        </article>
                    )}
                    {clickedImg && (
                        <Modal
                            clickedImg={clickedImg}
                            setClickedImg={setClickedImg}
                        />
                    )}
                </div>
                <aside className={isOpen ? "active" : null}>
                    {articleContent.map(item =>
                        <div key={item.id}>
                            <h2>{item.listTitle}</h2>
                            {item.list?.map((item, i) =>
                                <ul key={i}>
                                    <li><p>{item.listName}</p></li>
                                    {/* <hr/> */}
                                </ul>
                            )}
                        </div>
                    )}
                </aside>
            </div>
        </section>
    );
}

export default ReDoArticleLeft;