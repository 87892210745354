import React, { useState, useEffect, useCallback } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink } from "react-router-dom";
import Sidebar from "../sidebar/Sidebar";
import './css/NavbarSmall.css';

function NavbarSmall(props) {
    const { sidebar, setSidebar } = props;
    const [stickyClass, setStickyClass] = useState('');
    const [y, setY] = useState(window.scrollY);
    const showSidebar = () => setSidebar(!sidebar);

    const handleNavigation = useCallback(
        e => {
            const window = e.currentTarget;
            if (y > window.scrollY) {
                setStickyClass('');
            } else if (y < window.scrollY) {
                setStickyClass('sticky-nav-small');
            }
            setY(window.scrollY);
        }, [y]
    );

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    return (
        <nav className={`navbar-small-container ${stickyClass}`}>
            <div className="navbar-small-content">
                <div className="navbar-menu-icon" onClick={showSidebar}></div>
                {/* <MenuIcon onClick={showSidebar} style={{ color: 'var(--clr-neutral-black80)' }}/> */}


                <NavLink
                    to="/"
                    className="navbar-small-links"
                >
                    <div className="navbar-logo" ></div>
                </NavLink>
            </div>
            <Sidebar sidebar={sidebar} showSidebar={showSidebar} />
        </nav>
    );
}

export default NavbarSmall;