import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/ReDoSliderLeft.css";

function ReDoSliderLeft(props) {
    const { sliderContent, sliderBGColor, isMobile, isTablet } = props;

    const settingsMobile = {
        autoplay: true,
        autoplaySpeed: 7000,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const settingsTablet = {
        autoplay: true,
        autoplaySpeed: 7000,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
    };

    const settingsDesktop = {
        autoplay: true,
        autoplaySpeed: 7000,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
    };

    return (
        <div className="redo-slider-container-left">
            <div className="redo-slider-bg-left" style={{ backgroundColor: sliderBGColor }}></div>
            <div className="redo-slider-content-wrapper-left">
                <div className="redo-slider-content-left">
                    <div className="redo-slider-title-left">
                        <h5>Overview</h5>
                    </div>
                    <div className="redo-slider-arrow-bg-left" style={{ backgroundColor: sliderBGColor }}></div>
                    {isMobile ?
                        (
                            <Slider {...settingsMobile} className="redo-slider-left sm">
                                {sliderContent?.map(item => (
                                    <div key={item.id} className="redo-slider-item-left">
                                        <div className="redo-slider-item-content-left">
                                            <div className="redo-slider-item-img-left">
                                                <img src={item.img} alt="" />
                                            </div>
                                            <div className="redo-slider-item-details-left">
                                                <h5>{item.title}</h5>
                                                <p>{item.p1}</p>
                                                <p>{item.p2}</p>
                                                <p>{item.p3}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        ) : (isTablet ? (
                            <Slider {...settingsTablet} className="redo-slider-left">
                                {sliderContent?.map(item => (
                                    <div key={item.id} className="redo-slider-item-left">
                                        <div className="redo-slider-item-content-left">
                                            <div className="redo-slider-item-img-left">
                                                <img src={item.img} alt="" />
                                            </div>
                                            <div className="redo-slider-item-details-left">
                                                <h5>{item.title}</h5>
                                                <p>{item.p1}</p>
                                                <p>{item.p2}</p>
                                                <p>{item.p3}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        ) : (
                            <Slider {...settingsDesktop} className="redo-slider-left">
                                {sliderContent?.map(item => (
                                    <div key={item.id} className="redo-slider-item-left">
                                        <div className="redo-slider-item-content-left">
                                            <div className="redo-slider-item-img-left">
                                                <img src={item.img} alt="" />
                                            </div>
                                            <div className="redo-slider-item-details-left">
                                                <h5>{item.title}</h5>
                                                <p>{item.p1}</p>
                                                <p>{item.p2}</p>
                                                <p>{item.p3}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        ))}
                </div>
            </div>
        </div>
    );
}

export default ReDoSliderLeft;
