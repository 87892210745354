import React, { useState, useEffect } from "react";
import Axios from "axios";
import fileDownload from 'js-file-download';
import NavbarSmall from './components/navbar/NavbarSmall';
import Navbar from './components/navbar/Navbar';
import { Routes, Route } from 'react-router-dom';
import Media from 'react-media';
import { Fragment } from 'react';
import Home from './pages/home/Home';
import Portfolio from './pages/portfolio/Portfolio';
import ReDoHome from './pages/redo/ReDoHome';
import About from './pages/about/About';
import PetShopBlue from './pages/petshopblue/PetShopBlue';
import Starbucks from "./pages/starbucks/Starbucks";
import ECommerce from "./pages/ecommerce/ECommerce";
import Footer from './components/footer/Footer';
import PrivacyPolicy from './pages/legal/PrivacyPolicy';
import TermsOfUse from './pages/legal/TermsOfUse';
import Impressum from './pages/legal/Impressum';
import './App.css';

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [isTablet, setIsTablet] = useState(window.innerWidth < 1008);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1008);
  const [isLaptop, setIsLaptop] = useState(window.innerHeight < 675);
  const updateMedia = () => {
    if (isMobile < 480) {
      setIsMobile(window.innerWidth < 480);
    }
    if (isTablet < 1200) {
      setIsTablet(window.innerWidth < 1008);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const [sidebar, setSidebar] = useState(false);

  const download = (e) => {
    e.preventDefault();
    Axios({
      url: "http://localhost:4000",
      method: "GET",
      responseType: "blob"
    }).then((res) => {
      console.log('====================================');
      console.log(res);
      console.log('====================================');
      fileDownload(res.data, "brunopulhezecv.pdf")
    }).catch(e => {
      console.log('====================================');
      console.log(e);
      console.log('====================================');
    });
  }

  return (
    <div className={`App ${sidebar ? "hide-overflow" : "show-overflow"}`}>
      <Media queries={{
        small: "(max-width: 640px)",
        medium: "(min-width: 641px)"
      }}>
        {matches => (
          <Fragment>
            {matches.small &&
              <NavbarSmall sidebar={sidebar} setSidebar={setSidebar} />
            }
            {matches.medium &&
              <Navbar />
            }
          </Fragment>
        )}
      </Media>
      <div className='app-content'>
        <Routes>
          <Route path="/" element={<Home isMobile={isMobile} isTablet={isTablet} />} />
          <Route path="/redo" element={<ReDoHome isMobile={isMobile} isTablet={isTablet} isLaptop={isLaptop} />} />
          <Route path="/portfolio" element={<Portfolio isMobile={isMobile} isTablet={isTablet} />} />
          <Route path="/petshopblue" element={<PetShopBlue isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop} />} />
          <Route path="/starbucks" element={<Starbucks isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop} />} />
          <Route path="/e-commerce" element={<ECommerce isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop} />} />
          <Route path="/about" element={<About isMobile={isMobile} isTablet={isTablet} download={download} />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/impressum" element={<Impressum />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
