import React, { useState, useEffect, useRef } from "react";
import rectangle from "../../assets/home/rectangle.png";
import logo from "../../assets/ecommerce/ecommerce-logo.svg"
import "./css/ECommerceProjectCard.css";

const ECommerceProjectCard = (props) => {
    const { isMobile } = props;
    const targetRef = useRef();
    const [height, setHeight] = useState();

    const changeHeight = () => {
        const newHeight = targetRef.current.clientHeight;
        setHeight(newHeight);
    };

    useEffect(() => {
        setHeight(targetRef.current.clientHeight);
    });

    useEffect(() => {
        changeHeight();
    }, [targetRef]);

    useEffect(() => {
        window.addEventListener("resize", changeHeight);
    }, []);

    return (
        <div className="ecommerce-project-card-container" style={{ height: height }}>
            <div className="rectangle">
                <img ref={targetRef} src={rectangle} alt="" />
            </div>
            <div className="ecommerce-project-card-content">
                <div className="ecommerce-project-card-title">
                    <img src={logo} alt="" />
                </div>
                <div className="ecommerce-project-card-bg"></div>
            </div>
        </div>
    );
}

export default ECommerceProjectCard;