import React from "react";
import "./css/ReDoSketch.css";


function ReDoSketch() {

    return (
        <section className="redo-sketch-container">
            <div className="redo-sketch-bg-grey"></div>
            <div className="redo-sketch-bg"></div>            
        </section>
    );
}

export default ReDoSketch;