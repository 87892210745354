import { NavLink } from "react-router-dom";
import brunoLogo from "../../assets/home/bruno-logo-footer.png";
import LinkedIn from "../svg-icons/LinkedInIcon";
import Medium from "../svg-icons/MediumIcon";
import Github from "../svg-icons/GithubIcon";
import "./css/Footer.css";
const year = new Date().getFullYear();

export default function () {
    return (
        <footer>
            <div className="footer-content">
                <div className="footer-column footer-logo">
                    <h2><img src={brunoLogo} alt="" />Bruno's Portfolio</h2>
                </div>
                <div className="footer-column footer-icons">
                    <ul>
                        <li>
                            <a href="https://www.linkedin.com/in/bruno-pulheze/" target="_blank">
                                <LinkedIn />
                            </a>
                        </li>
                        <li>
                            <a href="https://medium.com/@brunopulheze/" target="_blank">
                                <Medium />
                            </a>
                        </li>
                        <li>
                            <a href="https://github.com/brunopulheze" target="_blank">
                                <Github />
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="footer-column footer-links">
                    {/* <ul>
                        <li>
                            <NavLink
                                to="/privacy-policy"
                            >
                                Privacy Policy
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/terms-of-use"
                            >
                                Terms of Use
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/impressum"
                            >
                                Impressum
                            </NavLink>
                        </li>
                    </ul> */}
                </div>
                <div className="footer-column footer-copyright">
                    <p>
                        Bruno's Portfolio Copyright <span>&#169;</span> {year}
                    </p>
                </div>
            </div>
        </footer>
    );
}