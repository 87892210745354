import React from 'react';
import SectionHero from '../../components/section-hero/SectionHero';
import Section from '../../components/section/Section';
import { hero, skills, styleGuide, colorPalette, typography, designSystem } from './helpers/petshopblue-data';
import './css/PetShopBlue.css';

const PetShopBlue = (props) => {
    const { isMobile, isTablet, isDesktop } = props;

    return (
        <main className='petshopblue-container'>
            <SectionHero data={hero} isMobile={isMobile} isTablet={isTablet} />
            <Section data={skills} isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop} />
            <Section data={styleGuide} isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop} />
            <Section data={colorPalette} isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop} />
            <Section data={typography} isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop} />
            <Section data={designSystem} isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop} />
        </main>
    );
}

export default PetShopBlue;