import React, { useState } from "react";
import Modal from "../../components/modal/Modal";
import SectionGallery from '../gallery/SectionGallery';
import magnifier from '../../assets/home/magnifier.svg';
import './css/Section.css';

const Section = (props) => {
    const { isMobile, isTablet, isDesktop, data } = props;
    const [clickedImg, setClickedImg] = useState(null);

    const handleClick = (item) => {
        setClickedImg(item.img);
    };

    const [isOpen, setIsOpen] = useState(false);
    const handleSlide = () => setIsOpen(!isOpen);
    const closeSlide = () => setIsOpen(false);

    return (
        <div className='section-container'>
            {data?.map((item, i) => (
                <div key={i} className='section-content' style={{ marginBottom: item.marginBottom }}>
                    {item.hr && isMobile ? <hr /> : null}
                    <h2 style={{
                        marginTop: isMobile ? item.marginTopSmall : item.marginTop,
                        paddingBottom: isMobile ? item.paddingBottomSmall : item.paddingBottom
                    }}>
                        {item.brandTitle}
                    </h2>
                    <div className='section-box' style={{ flexDirection: isDesktop ? "row" : isTablet ? item.flexDirectionReverse : item.flexDirection }}>
                        <div className='section-item'>
                            <h3 style={{ color: item.color, order: isDesktop ? "" : isTablet ? item.orderReverse : item.order }}>{item.sectionTitle}</h3>
                        </div>
                        <div className='section-item'>
                            <h3>{item.title}</h3>
                            <p>{item.p1}</p>
                            <p>{item.p2}</p>
                            <p>{item.p3}</p>
                            <a href={item.a} target="/blank" style={{ color: item.color }}>{item.aText}</a>
                        </div>
                        <div className='section-item' style={{ order: isDesktop ? 0 : isTablet ? item.orderReverse : item.order }}>
                            {item.gallery ? <SectionGallery /> : (
                                <div className={item.hover ? "section-item-img-hover" : "section-item-img"} onClick={item.modal ? () => handleClick(item, i) : null}>
                                    <img src={item.img} alt="" />
                                    {item.hover && (
                                        <div className="section-item-img-magnifier">
                                            <img src={magnifier} alt="" />
                                            <span>view</span>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    {clickedImg && (
                        <Modal
                            clickedImg={clickedImg}
                            setClickedImg={setClickedImg}
                        />
                    )}
                </div>
            ))
            }

        </div >
    );
}

export default Section;