import React, { useState } from "react";
import Slider from "react-slick";
import Modal from "../../components/modal/Modal";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./css/ReDoArticleRight.css";

function ReDoArticleRight(props) {
    const {articleContent, isMobile} = props;
    const [clickedImg, setClickedImg] = useState(null);

    const handleClick = (item) => {
        setClickedImg(item.img);
    };

    const [isOpen, setIsOpen] = useState(false);
    const handleSlide = () => setIsOpen(!isOpen);
    const closeSlide = () => setIsOpen(false);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
            <section className="redo-article-container-right">
                <div className={isOpen ? "redo-article-aside-bg-right active" : "redo-article-aside-bg-right"} onClick={handleSlide}></div>
                <div className="redo-article-content-wrapper-right" onClick={closeSlide}>
                    <div className={isOpen ? "redo-article-content-right active" : "redo-article-content-right"}>
                        <article>
                        {articleContent.map(item =>
                            <div key={item.id} id={item.title === 'Intro' ? "intro" : null }>
                                <h2>{item.title}</h2>
                                <p>
                                    {item.p}
                                </p>
                            
                            {item.articleSlider? (
                                <Slider {...settings} className="redo-slider-article-right">
                                    {item.articleSlider.map((item, i) =>
                                    <div key={i} className="redo-slider-article-item-right">
                                        <div className="redo-slider-article-item-content-right">
                                            <div className="redo-slider-article-item-img-right">
                                                
                                                <img src={item.img} alt="" onClick={() => handleClick(item, i)}/>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    )}
                                </Slider>
                            ) : (null)}
                            </div>
                        )}
                        </article>
                        {clickedImg && (
                            <Modal
                                clickedImg={clickedImg}
                                setClickedImg={setClickedImg}
                            />
                        )}
                    </div>

                    <aside className={isOpen ? "active" : null}>
                        {articleContent.map(item =>
                            <div key={item.key}>
                            <h2>{item.listTitle}</h2>
                            {item.list?.map((item, i) =>
                            <ul key={i}>
                                <li>
                                    <h3>{item.listName}</h3>
                                    <p>{item.listText}</p>    
                                </li>
                                <hr/>
                            </ul>
                            )}
                        </div>
                        )}
                    </aside>
                </div>
            </section>
    );
}

export default ReDoArticleRight;