import React, { useState, useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import Button from '@mui/material/Button';
import PDFIcon from '../svg-icons/PDFIcon';
import contactFormEnvelope from '../../assets/about/contact-form-image.png';
import contactFormSent from '../../assets/about/contact-form-image.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ReCAPTCHA from "react-google-recaptcha";
import './css/ContactForm.css';

const Result = (props) => {
    return (
        <div className='contact-form-success-msg'>
            <h2>Your message has been sent.
                <br />
                I will get in touch with you soon, thank you!
            </h2>
            <img src={contactFormSent} alt="" />
        </div>
    )
}

const ContactForm = (props) => {
    const { isMobile, download } = props;
    const [isVerified, setIsVerified] = useState(true);
    const handleOnChange = () => {
        setIsVerified(false);
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: '#196eb9',
            }
        }, typography: {
            button: {
                textTransform: 'none'
            }
        }
    });
    const [rerender, setRerender] = useState(false);
    const targetRef = useRef();
    const [height, setHeight] = useState();

    useEffect(() => {
        setTimeout(() => {
            setRerender(!rerender);
        }, 1);
        const imgRefHeight = targetRef.current.clientHeight;
        setHeight(imgRefHeight);
    }, [rerender]);

    const [result, showResult] = useState(false);
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_41v08lw', 'template_idjmbsw', form.current, process.env.REACT_APP_EMAILJS_KEY)
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
        showResult(true);
    };

    return (
        <div className="contact-form-container" /* style={{height: result ? "auto" : isMobile? "170vh" : "100vh"}} */>
            <div className="contact-form-content">
                <div ref={targetRef} id="contact-form" className='contact-form-headline'>
                    <h2>Get in Touch</h2>
                    {isMobile ? (
                        <p>You can write me a message
                            <br /> or visit my profile on
                            <br /> Medium, Github and LinkedIn.
                            <br />
                            Thank you.
                        </p>
                    ) : (
                        <p>You can write me a message or visit my profile on
                            <br /> Medium, Github and LinkedIn. Thank you.
                        </p>
                    )}
                    <div className='contact-form-headline-img'>
                        <img src={contactFormEnvelope} alt="" />
                    </div>
                </div>
                <div className={result ? "display-none" : "contact-form"} /* style={{height: height }} */>

                    <form ref={form} action='' onSubmit={sendEmail}>
                        <div className="contact-form-input-group">
                            <label className="contact-form-input-underlined">
                                <input type="text" name="fullName" required />
                                <span className="contact-form-input-label">Your Name</span>
                            </label>
                        </div>
                        <div className="contact-form-input-group">
                            <label className="contact-form-input-underlined">
                                <input type="text" name="email" required />
                                <span className="contact-form-input-label">Your Email</span>
                            </label>
                        </div>
                        <div className="contact-form-input-group">
                            <label className="contact-form-input-underlined">
                                <textarea name='message' className="contact-form-field" required></textarea>
                                <span className="contact-form-input-label">Message</span>
                            </label>
                        </div>
                        <div className='contact-form-submit'>
                            <div className='contact-form-recaptcha'>
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SECRET_KEY}
                                    onChange={handleOnChange}
                                />
                            </div>
                            {/* <div className='contact-form-pdf' onClick={(e) => download(e)}>
                                <PDFIcon />
                                <p>Download CV</p>
                            </div> */}
                            <ThemeProvider theme={theme}>
                                <Button
                                    disabled={isVerified}
                                    type='submit'
                                    variant="contained"
                                    disableElevation
                                    style={{ color: "#fff" }}
                                >
                                    Send Message
                                </Button>
                            </ThemeProvider>
                        </div>
                    </form>


                    {/* <form ref={form} action='' onSubmit={sendEmail}>
                        <input type="text" className="contact-form-field" name='fullName' placeholder="Your Name" required/>
                        <input type="text" className="contact-form-field" name='email' placeholder="Your Email" required/>
                        <textarea placeholder="Message" name='message' className="contact-form-field" required></textarea>
                        <ThemeProvider theme={theme}>
                            <Button
                            variant="contained" 
                            disableElevation
                            style={{color:"#fff"}}
                            >
                                Send Message
                            </Button>
                        </ThemeProvider>
                        <div className='contact-form-success-msg'>{ result ? <Result/> : null }</div>
                    </form> */}
                </div>
            </div>
            {/*             <div className="about-hero-pattern02">
                <img src={pattern02} alt=""/>
            </div> */}
            {result ? <Result /> : null}
        </div>
    );
}

export default ContactForm;