import React from "react";
import Media from 'react-media';
import { Fragment } from 'react'
import "./css/Header.css";

function HomeHeader() {
  return (
    <Media queries={{
      small: "(max-width: 640px)",
      medium: "(min-width: 641px)"
    }}>
      {matches => (
        <Fragment>
          {matches.small &&
            <header className="home-header">
              <h1>Bruno Pulheze's Portfolio</h1>
              <p>Frontend Developer | Webdesigner</p>
            </header>
          }
          {matches.medium &&
            <header className="home-header">
              <h1>Bruno Pulheze's Portfolio</h1>
              <p>Frontend Developer | Webdesigner</p>
            </header>
          }
        </Fragment>
      )}
    </Media>
  );
}

export default HomeHeader;