import React from "react";
import HomeHeader from "../../components/header/Header";
import heroMockup from "../../assets/redo/redo-hero-mockup.png";
import arrowRightIcon from "../../assets/redo/arrow-right-icon.png";
import ReDoSliderRight from "./ReDoSliderRight";
import ReDoArticleLeft from "./ReDoArticleLeft";
import ReDoArticleRight from "./ReDoArticleRight";
import ReDoUserResearch from "./ReDoUserResearch";
import ReDoUserPersona from "./ReDoUserPersona";
import ReDoMoodboard from "./ReDoMoodboard";
import ReDoSketch from "./ReDoSketch";
import ReDoMidFi from "./ReDoMidFi";
import ReDoHighFi from "./ReDoHighFi";
import {
    redoSliderBGColor,
    redoSliderHero,
    redoIntroArticle,
    redoSliderUserResearch,
    redoCompetitiveAnalysisArticle,
    redoSliderUserPersona,
    redoJourneyMap,
    redoIAArticle,
    redoStyleGuide
} from "./helpers/data";
import "./css/ReDoHome.css";

function ReDoHome(props) {
    const { isMobile, isTablet, isLaptop } = props;
    return (
        <div className="redo-container">
            <div className="redo-hero-slider-container">
                <ReDoSliderRight sliderContent={redoSliderHero} sliderBGColor={redoSliderBGColor[0]} isMobile={isMobile} isTablet={isTablet} />
            </div>
            <section className="redo-hero">
                <div className="redo-hero-bg"></div>
                <div className="redo-hero-content">
                    <div className="redo-hero-headline">
                        <h3>CASE STUDY</h3>
                        <h2>Re | Do</h2>
                        <h4>MAGAZINE</h4>
                        <h3>User Centered Design</h3>
                        <h1>The Creation of <br />
                            a Fashion Editorial <br />
                            with Design Thinking</h1>
                        {
                            isMobile ?
                                (null) : (
                                    <p>
                                        A Case Study about the creation and <br />
                                        development of the online fashion magazine <br />
                                        Re | Do applying UCD techniques
                                    </p>
                                )
                        }
                        <a className="redo-hero-cta" href="#intro">
                            <h3>EXPLORE THE ISSUE</h3>
                            <img src={arrowRightIcon} alt="" />
                        </a>
                    </div>
                    {isMobile ?
                        (
                            null
                        ) :
                        (
                            <aside className="redo-hero-aside">
                                <HomeHeader />
                                <div className="redo-hero-mockup-card">
                                    <img src={heroMockup} alt="Hero Mockup" />
                                    <h3>Re | Do Magazine</h3>
                                    <p>New is the New New</p>
                                </div>
                            </aside>
                        )}
                </div>
            </section>
            <ReDoArticleRight articleContent={redoIntroArticle} isMobile={isMobile} isTablet={isTablet} />
            <ReDoUserResearch sliderContent={redoSliderUserResearch} sliderBGColor={redoSliderBGColor[1]} isMobile={isMobile} isTablet={isTablet} />
            <ReDoArticleRight articleContent={redoCompetitiveAnalysisArticle} isMobile={isMobile} isTablet={isTablet} />
            <ReDoUserPersona sliderContent={redoSliderUserPersona} sliderBGColor={redoSliderBGColor[2]} isMobile={isMobile} isTablet={isTablet} isLaptop={isLaptop} />
            <ReDoArticleLeft articleContent={redoJourneyMap} isMobile={isMobile} isTablet={isTablet} />
            <ReDoMoodboard isMobile={isMobile} />
            <ReDoArticleRight articleContent={redoIAArticle} isMobile={isMobile} isTablet={isTablet} />
            <ReDoSketch />
            <ReDoMidFi isMobile={isMobile} isTablet={isTablet} />
            <ReDoArticleRight articleContent={redoStyleGuide} isMobile={isMobile} isTablet={isTablet} />
            {/* <ReDoHighFi isMobile={isMobile} isTablet={isTablet} /> */}
        </div>
    );
}

export default ReDoHome;