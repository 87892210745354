import React from "react";
import Header from "../../components/header/Header";
import HomeCardsContainer from "./HomeCardsContainer";
import linkPreview from "../../assets/home/portfolio.png";
import "./css/Home.css";

function Home(props) {
    const { isMobile, isTablet, isDesktop } = props;
    return (
        <main className="home-container">
            <Header />
            <HomeCardsContainer isMobile={isMobile} isTablet={isTablet} />
            <img className="home-link-preview" src={linkPreview} alt="" />
        </main>
    );
}

export default Home;