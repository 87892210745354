import React, { useState, useEffect, useRef } from "react";
import avatar from "../../assets/about/about-home-card-avatar.png";
import rectangle from "../../assets/home/rectangle.png";
import "./css/AboutHomeCard.css";

const AboutHomeCard = (props) => {
    const { isMobile } = props;
    const targetRef = useRef();
    const [height, setHeight] = useState();

    const changeHeight = () => {
        const newHeight = targetRef.current.clientHeight;
        setHeight(newHeight);
    };

    useEffect(() => {
        setHeight(targetRef.current.clientHeight);
    });

    useEffect(() => {
        changeHeight();
    }, [targetRef]);

    useEffect(() => {
        window.addEventListener("resize", changeHeight);
    }, []);

    return (
        <div className="about-home-card-container" style={{ height: height }}>
            <div className="rectangle">
                <img ref={targetRef} src={rectangle} alt="" />
            </div>
            <h2>about me</h2>
            <div className="about-home-card-avatar">
                <img src={avatar} alt="" />
            </div>
            <svg id="svg" viewBox="0 0 1440 700" xmlns="http://www.w3.org/2000/svg" className="transition duration-300 ease-in-out delay-150">
                <path
                    d="M 0,700 C 0,700 0,350 0,350 C 90.92857142857142,404.07142857142856 181.85714285714283,458.14285714285717 311,456 C 440.14285714285717,453.85714285714283 607.5000000000001,395.5 726,352 C 844.4999999999999,308.5 914.1428571428571,279.8571428571429 1025,282 C 1135.857142857143,284.1428571428571 1287.9285714285716,317.07142857142856 1440,350 C 1440,350 1440,700 1440,700 Z" stroke="none" strokeWidth="0" className="transition-all duration-300 ease-in-out delay-150 home-about-wave-bg-path-0">
                </path>
            </svg>
        </div>
    );
}

export default AboutHomeCard;