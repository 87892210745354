import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/ReDoSliderRight.css";

function ReDoSliderRight(props) {
    const { sliderContent, sliderBGColor, isMobile, isTablet } = props;

    const settingsMobile = {
        autoplay: true,
        autoplaySpeed: 7000,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const settingsTablet = {
        autoplay: true,
        autoplaySpeed: 7000,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
    };

    const settingsDesktop = {
        autoplay: true,
        autoplaySpeed: 7000,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
    };

    return (
        <div className="redo-slider-container-right">
            <div className="redo-slider-bg-right" style={{ backgroundColor: sliderBGColor }}></div>
            <div className="redo-slider-content-wrapper-right">
                <div className="redo-slider-content-right">
                    <div className="redo-slider-title-right">
                        <h5>Overview</h5>
                    </div>
                    <div className="redo-slider-arrow-bg-right" style={{ backgroundColor: sliderBGColor }}></div>
                    {isMobile ? (
                        <Slider {...settingsMobile} className="redo-slider-right sm">
                            {sliderContent?.map(item => (
                                <div key={item.id} className="redo-slider-item-right">
                                    <div className="redo-slider-item-content-right">
                                        <div className="redo-slider-item-img-right">
                                            <img src={item.img} alt="" />
                                        </div>
                                        <div className="redo-slider-item-details-right">
                                            <h5>{item.title}</h5>
                                            <p>{item.p1}</p>
                                            <p>{item.p2}</p>
                                            <p>{item.p3}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    ) : (isTablet ? (
                        <Slider {...settingsTablet} className="redo-slider-right">
                            {sliderContent?.map(item => (
                                <div key={item.id} className="redo-slider-item-right">
                                    <div className="redo-slider-item-content-right">
                                        <div className="redo-slider-item-img-right">
                                            <img src={item.img} alt="" />
                                        </div>
                                        <div className="redo-slider-item-details-right">
                                            <h5>{item.title}</h5>
                                            <p>{item.p1}</p>
                                            <p>{item.p2}</p>
                                            <p>{item.p3}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    ) : (
                        <Slider {...settingsDesktop} className="redo-slider-right">
                            {sliderContent?.map(item => (
                                <div key={item.id} className="redo-slider-item-right">
                                    <div className="redo-slider-item-content-right">
                                        <div className="redo-slider-item-img-right">
                                            <img src={item.img} alt="" />
                                        </div>
                                        <div className="redo-slider-item-details-right">
                                            <h5>{item.title}</h5>
                                            <p>{item.p1}</p>
                                            <p>{item.p2}</p>
                                            <p>{item.p3}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ReDoSliderRight;