import React, { useState } from "react";
import ReDoHomeCard from "./ReDoHomeCard";
import PortfolioHomeCard from "./PortfolioHomeCard";
import AboutHomeCard from "./AboutHomeCard";
import PetShopBlueHomeCard from "./PetShopBlueHomeCard";
import { NavLink as Link } from "react-router-dom";
import "./css/HomeCardsContainer.css";


function HomeCardsContainer(props) {
    const { isMobile, isTablet } = props;

    return (
        <main className="home-cards-container">
            <div className="home-cards-content">
                {/* <Link to="/redo" className="home-card">
                        <ReDoHomeCard height={height} setHeight={setHeight} isMobile={isMobile} />
                    </Link> */}
                {/* <Link to="/petshopblue" className="home-card">
                    <PetShopBlueHomeCard height={height} setHeight={setHeight} isMobile={isMobile} />
                </Link> */}
                <Link to="/portfolio" className="home-card">
                    <PortfolioHomeCard isMobile={isMobile} />
                </Link>
                <Link to="/about" className="home-card">
                    <AboutHomeCard isMobile={isMobile} />
                </Link>
            </div>






        </main >
    );
}

export default HomeCardsContainer;