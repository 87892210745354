import React from "react";
import "./css/MediumIcon.css";

const MediumIcon = () => {
    return (
        <svg
            className="medium-icon"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 5757.49 3266.98"
        >
            <defs>
                <style type="text/css"></style>
            </defs>
            <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <g id="_1560946940000">
                    <path
                        className="fil0"
                        d="M1426.35 11.63c-2131.52,251.23 -1770.32,3500.5 393.85,3240.56 822.66,-98.81 1522.47,-863.27 1405.57,-1828.78 -99.27,-819.73 -855.87,-1522.98 -1799.42,-1411.78z"
                    />
                    <path
                        className="fil0"
                        d="M3409.33 1841.17c195.12,2054.29 1724.15,1577.45 1607.19,-424.7 -32.22,-551.56 -347.78,-1537.24 -996.18,-1281.35 -434.84,171.61 -673,1053.48 -611.01,1706.05z"
                    />
                    <path
                        className="fil0"
                        d="M5470.72 3005.05c298.43,-165.45 286.63,-979.27 286.71,-1375.16 0.08,-403.1 10.08,-1194.85 -286.71,-1372.43 -293.77,222.38 -279.5,944.6 -279.5,1372.43 0.01,425.65 -13.33,1158.64 279.5,1375.16z"
                    />
                </g>
            </g>
        </svg>
    );
}

export default MediumIcon;