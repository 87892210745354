import React from 'react';
import Projects from '../projects/Projects';
import './css/Portfolio.css';

const Portfolio = (props) => {
    const { isMobile } = props;
    return (
        <section className='portfolio-container'>
            <div className='portfolio-content'>
                <div className='portfolio-headline'>
                    <h2 className=''>
                        Bruno Pulheze's Portfolio
                    </h2>
                    {isMobile ? (
                        <p className=''>
                            Branding & Webdesign
                            <br />Frontend Web Development
                        </p>
                    ) : (
                        <p className=''>
                            These are my latest work including
                            <br />
                            Branding & Webdesign | Frontend Web Development
                        </p>
                    )}

                </div>
                <Projects />
            </div>
        </section>
    );
}

export default Portfolio;