import ecommerceHero from '../../../assets/ecommerce/ecommerce-hero.svg';
import technologiesIcons from '../../../assets/ecommerce/technologies-icons.png';


export const hero = [
    {
        brandTitle: "E-Commerce",
        fontFamily: "Readex Pro",
        fontSizeSmall: "1.5rem",
        fontSize: "2.1rem",
        fontWeight: "100",
        heroTitle: "Shopping Cart Page",
        colorTitle: "#5b5098",
        color: "#4995C4",
        title: "Overview",
        p1: "My goal was to develop a shopping cart page and implement with custom React Hooks and Context the logic of adding and removing products on an e-commerce website.",
        p2: "For this purpose, I created the brand Skincare Natural and added the Cosmetics section, where the user flow is to add products from the page into the shopping cart and checking the amount of products added and total price.",
        p3: "You can visit the website",
        a1: "https://brunopulheze.github.io/e-commerce-typescript-bootstrap/",
        a1Text: "here ",
        p4: "or view the repository on",
        a2: "https://github.com/brunopulheze/e-commerce-typescript-bootstrap",
        a2Text: "Github.",
        img: ecommerceHero
    }
];

export const skills = [
    {
        hr: true,
        sectionTitle: "Skills",
        color: "#4995C4",
        title: "Technologies",
        p1: "This project was developed with React, Bootstrap and TypeScript, which is a popular and trusted technology used by the IT industry.",
        p2: "TypeScript always points out the compilation errors at the time of development due to the precise defining through typing and has access to E56 JavaScripts features.",
        p3: "I also implemented Bootstrap CSS framework to speed up the development and take advantage of its responsive components and global styles.",
        img: technologiesIcons,
        hover: false,
        modal: false,
        flexDirectionReverse: "column",
        orderReverse: -1,
        marginBottom: "10rem"
    }
];
