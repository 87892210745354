import React, { useState, useEffect, useRef } from "react";
import rectangle from "../../assets/home/rectangle.png";
import logoMystika from "../../assets/mystika/logo-mystika.svg";
import charriot from "../../assets/mystika/tarot-charriot.jpg";
import world from "../../assets/mystika/tarot-world.jpg";
import lovers from "../../assets/mystika/tarot-lovers.jpg";
import wheel from "../../assets/mystika/tarot-wheel.jpg";
import strength from "../../assets/mystika/tarot-strength.jpg";
import temperance from "../../assets/mystika/tarot-temperance.jpg";
import star from "../../assets/mystika/tarot-star.jpg";
import "./css/MystikaProjectCard.css";

const MystikaProjectCard = (props) => {
    const { isMobile } = props;
    const targetRef = useRef();
    const [height, setHeight] = useState();

    const changeHeight = () => {
        const newHeight = targetRef.current.clientHeight;
        setHeight(newHeight);
    };

    useEffect(() => {
        setHeight(targetRef.current.clientHeight);
    });

    useEffect(() => {
        changeHeight();
    }, [targetRef]);

    useEffect(() => {
        window.addEventListener("resize", changeHeight);
    }, []);

    return (
        <div className="mystika-project-card-container" data-ribbon="Coming soon" style={{ height: height }}>
            <div className="rectangle">
                <img ref={targetRef} src={rectangle} alt="" />
            </div>
            <div className="mystika-project-card-ribbon" ></div>
            <div className="mystika-project-card-bg"></div>
            <div className="mystika-project-card-content">
                <img src={logoMystika} alt="Mystika Logo" />
                <div className="mystika-project-card-tarot-pack">
                    <img src={charriot} alt="Mystika Logo" />
                    <img src={world} alt="Mystika Logo" />
                    <img src={lovers} alt="Mystika Logo" />
                    <img src={wheel} alt="Mystika Logo" />
                    <img src={strength} alt="Mystika Logo" />
                    <img src={temperance} alt="Mystika Logo" />
                    <img src={star} alt="Mystika Logo" />
                </div>
            </div>
        </div>
    );
}

export default MystikaProjectCard;