import React, { useState, useEffect, useRef } from "react";
import rectangle from "../../assets/home/rectangle.png";
import portfolioImage from "../../assets/portfolio/portfolio-home-card.jpg";
import portfolioAvatar from "../../assets/portfolio/portfolio-home-card-avatar.png";
import portfolioBG from "../../assets/portfolio/portfolio-home-card-bg.jpg";
import largeWheel from "../../assets/portfolio/large-wheel.png";
import mediumWheel from "../../assets/portfolio/medium-wheel.png";
import smallWheel from "../../assets/portfolio/medium-wheel.png";
import "./css/PortfolioHomeCard.css";

const PortfolioHomeCard = (props) => {
    const { isMobile } = props;
    const targetRef = useRef();
    const [height, setHeight] = useState();

    const changeHeight = () => {
        const newHeight = targetRef.current.clientHeight;
        setHeight(newHeight);
    };

    useEffect(() => {
        setHeight(targetRef.current.clientHeight);
    });

    useEffect(() => {
        changeHeight();
    }, [targetRef]);

    useEffect(() => {
        window.addEventListener("resize", changeHeight);
    }, []);

    return (
        <div className="portfolio-home-card-container" style={{ height: height }}>
            <div className="rectangle">
                <img ref={targetRef} src={rectangle} alt="" />
            </div>
            <h2>portfolio</h2>
            {/*             <div className="portfolio-home-card-image">
                <img src={portfolioImage} alt="" />
            </div> */}
            <div className="portfolio-home-card-image">
                <img src={portfolioBG} alt="" />
            </div>
            <div className="portfolio-home-card-avatar">
                <img src={portfolioAvatar} alt="" />
            </div>
            <div className="portfolio-home-card-large-wheel">
                <img src={largeWheel} alt="" />
            </div>
            <div className="portfolio-home-card-medium-wheel">
                <img src={mediumWheel} alt="" />
            </div>
            <div className="portfolio-home-card-small-wheel">
                <img src={smallWheel} alt="" />
            </div>
        </div>
    )
}

export default PortfolioHomeCard