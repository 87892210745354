import React, { useState, useEffect, useRef } from "react";
import dog from "../../assets/petshopblue/dog.png";
import ballShadow from "../../assets/petshopblue/ball-shadow.png";
import rectangle from "../../assets/home/rectangle.png";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import "./css/PetShopBlueProjectCard.css";

const PetShopBlueProjectCard = (props) => {
    const { isMobile } = props;
    const [height, setHeight] = useState();
    const targetRef = useRef();

    const changeHeight = () => {
        const newHeight = targetRef.current.clientHeight;
        setHeight(newHeight);
    };

    useEffect(() => {
        setHeight(targetRef.current.clientHeight);
    });

    useEffect(() => {
        changeHeight();
    }, [targetRef]);

    useEffect(() => {
        window.addEventListener("resize", changeHeight);
    }, []);

    return (
        <div className="petshopblue-project-card-container" style={{ height: height }}>
            <div className="rectangle">
                <img ref={targetRef} src={rectangle} alt="" />
            </div>
            <div className="petshopblue-project-card-content">
                <div className="petshopblue-project-card-logo-box">
                    <div className="petshopblue-project-card-logo-bg-front"></div>
                    <div className="petshopblue-project-card-logo-bg-back"></div>
                    <div className="petshopblue-project-card-ball">
                        {/* <img src={ball} alt="ball" /> */}
                    </div>
                    <div className="petshopblue-project-card-ball-shadow">
                        <img src={ballShadow} alt="ball shadow" />
                    </div>
                    <div className="petshopblue-project-card-dog">
                        <img src={dog} alt="dog" />
                    </div>
                </div>
                <div className="petshopblue-project-card-title">
                    <h2>Pet Shop Blue</h2>
                </div>
            </div>
        </div>
    );
}

export default PetShopBlueProjectCard;